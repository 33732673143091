import { render, staticRenderFns } from "./Unipay.vue?vue&type=template&id=694d0cf3&functional=true"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports